import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/opt/build/repo/src/templates/page.jsx";
import { Button } from '../../components/Button';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Ďakujeme!`}</MDXTag>{` Budeme ťa čo najskôr kontaktovať.`}</MDXTag>
      <Button to="/zapoj-sa">Ďalšie možnosti ako sa zapojiť</Button>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      